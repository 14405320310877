<template>
<div>
    <detail-form 
        :is-banner-detail-sidebar-active.sync="isBannerDetailSidebarActive"
        :image-data="imageData"
        @refetch-data="getData((bannerData?bannerData.id:0))" 
       
    />
    <b-sidebar
     id="banner-form-sidebar"
     class="banner-form-sidebar"
     :visible="isBannerUploadSidebarActive"
     bg-variant="white"
     sidebar-class="sidebar-lg"
     shadow
     backdrop
     no-header
     right
     @shown="getData((bannerData?bannerData.id:0))"
     @hidden="resetData"
     @change="(val) => $emit('update:is-banner-upload-sidebar-active', val)"
>
<template #default="{ hide }"> 
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
           {{ bannerData?bannerData.title:'Upload Image' }}
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />
    </div>
    <div class="dropbox">
        <b-form-file
        multiple
            v-model="Data.images"
            class="input-file"
            accept=".jpg, .png, .gif"
            placeholder="Choose file"
            @input="onFileInputChange(bannerData.id)"
        />
        <p >
            Drag your file(s) here to begin<br> or click to browse
        </p>
    </div>
    <draggable
          :list="files"
          tag="ul"
          ref="listImagages"
          group="people"
          @change="undateImage"
          class="list-group list-group-flush cursor-move"
    >
        <b-list-group-item
            v-for="(listItem, index) in files"
            :key="index"
            tag="li"
          >
        <div class="d-flex">
            <div>
            <b-img-lazy
                  
                :src="listItem.src"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
                />
                <div class="mt-2">
                    <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'"  @click="updateDetail(listItem)" class="ml-1" variant="outline-success"><feather-icon icon="EditIcon" /></b-button>
                    <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1" @click="removeImageConfirm(listItem.id)" variant="outline-danger"><feather-icon icon="XIcon" /></b-button>
                </div>
            </div>
            <div class="ml-25">
                
                <b-card-text class="mb-0 font-weight-bold">
                  <h1>Title : {{ listItem.title }}</h1>
                  <h3>Sub Title : {{ listItem.sub_title}}</h3>
                </b-card-text>
                <small>Short Description : {{ listItem.short_description }}</small>
               
                <b-card-text class="font-weight-bold">
                    <small>Url : {{ listItem.url }}</small>
                </b-card-text>
                <b-card-text>
                    <small>Status : {{ listItem.is_active }}</small>
                </b-card-text>
            </div>
           
            
        </div>
        </b-list-group-item>
    </draggable>

</template>
</b-sidebar>
</div>
</template>
<script>
import draggable from 'vuedraggable'
 import {
   BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab
 } from 'bootstrap-vue'
 //upload
 import {
  BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,BListGroup,BListGroupItem,BImgLazy
 } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import DetailForm from './DetailForm.vue'
export default {
    components: {
        BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BButton,BTabs, BTab,
        BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink,BFormFile,
        draggable,BListGroup,BListGroupItem,BImgLazy,
        DetailForm
   },
   directives: {
     Ripple,
   },
   model: {
     prop: 'isBannerUploadSidebarActive',
     event: 'update:is-banner-upload-sidebar-active',
   },
   props: {
    isBannerUploadSidebarActive: {
       type: Boolean,
       required: true,
     },
    
     bannerData: {
       type: Object,
       required: false,
     },
   },
   setup(props, { emit }) {
    const toast = useToast();
    const isBannerDetailSidebarActive = ref(false);
    const blankData = {
          
          
        images: [],
          
        
      }
    const files = ref([]);
    const imageData = ref({});
    const Data = ref(JSON.parse(JSON.stringify(blankData)))
    const getData = (id)=>{
        //console.log('data',data);
        let params = {
            id:id,
            order_by:'ordering_count ASC'
        }
        store.dispatch('app-banner/fetchImageData', params)
            .then((res) => {
                if(res.data.success){
                    let images = res.data.data
                   
                    files.value = JSON.parse(JSON.stringify(images))
                }
               
              
        });
    }
    const updateDetail = (item)=>{
        isBannerDetailSidebarActive.value = true;
       
        imageData.value  = JSON.parse(JSON.stringify(item));
    }
    
    const resetData = () => {
        
           Data.value = JSON.parse(JSON.stringify(blankData))
    }
   
    const onFileInputChange = async (id)=>{
      
        let images = Data.value.images;
        const userStorage = JSON.parse(localStorage.getItem('userData'));
       
        let sm = images.map((ele,index)=>{
            let formData = new FormData();
            formData.append('images', ele);
            formData.append('path', '/var/www/html/uploadfiles/uploads/music/banner/');
            formData.append('savepath', 'uploads/music/banner/');
            formData.append('created_by', userStorage.id);
            store.dispatch('app-banner/uploadimages', {formData:formData,id:id})
            .then((res) => {
                 getData(id);
              
            });
        });
       
       // console.log('id',images);
    }
    const undateImage = ()=>{
         
         store.dispatch('app-banner/updateOrdering',files.value)
         
         
     }
    const refetchData = async ()=>{
        //listImagages.value.refesh();
        //imageData.value  = JSON.parse(JSON.stringify(item));

    }
    const removeImage = async (id)=>{
            store.dispatch('app-banner/fetchImageDelete',id).then(response=>{
                var ind = files.value.findIndex(p => p.id == id);
                files.value.splice(ind, 1); 

            })
    
  
            
        }
    return {
        Data,
        getData,
        resetData,
        onFileInputChange,
        files,
        undateImage,
        isBannerDetailSidebarActive,
        updateDetail,
        imageData,
        refetchData,
        removeImage

    }
   },
   methods:{
        removeImageConfirm(id){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete Banner Image ID.'+id, {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                if(value === true){
                this.removeImage(id)
                }
            
                //this.deleteData(id)
                })

        }
        
    }
    
}
</script>
<style lang="scss">
.banner-form-sidebar{
    .dropbox{
        min-height: 30vh;
      
        .input-file {
           
            min-height: 30vh;
            
            
        }
    }
}
</style>