<template>
    <b-modal 
    :visible="isBannerDetailSidebarActive"
    size="l"
    hide-footer
    
    @change="(val) => $emit('update:is-banner-detail-sidebar-active', val)"
    @shown="getData(imageData)"

    >
    <template #modal-title>
      Image  {{ Data?Data.id:'' }}
    </template>
    <validation-observer
         #default="{ handleSubmit }"
         ref="refFormObserver"
       >
       <b-form
           class="p-2"
           @submit.prevent="handleSubmit(onSubmit)"
           @reset.prevent="resetForm"
         >
            <validation-provider
             #default="validationContext"
             name="Title"
           >
             <b-form-group
               label="Title"
               label-for="title"
             >
               <b-form-input
                 id="title"
                 v-model="Data.title"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="Title"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
            </validation-provider>
            <validation-provider
             #default="validationContext"
             name="Sub Title"
             rules="min:0"
           >
             <b-form-group
               label="Sub Title"
               label-for="sub_title"
             >
               <b-form-input
                 id="sub_title"
                 v-model="Data.sub_title"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="Sub Title"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
            </validation-provider>
            <validation-provider
             #default="validationContext"
             name="short_descripiton"
             rules="min:0"
           >
             <b-form-group
               label="Short Description"
               label-for="short_descripiton"
             >
              <b-form-textarea id="short_description" autofocus v-model="Data.short_description" ></b-form-textarea>
               
              
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
            </validation-provider>
            <!--<validation-provider
             #default="validationContext"
             name="Url"
             rules="min:0"
           >
             <b-form-group
               label="Url"
               label-for="url"
             >
               <b-form-input
                 id="url"
                 v-model="Data.url"
                 autofocus
                 :state="getValidationState(validationContext)"
                 trim
                 placeholder="Url"
               />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
            </validation-provider>-->
            <validation-provider
             #default="validationContext"
             name="IsActive"
             rules="min:0"
           >
             <b-form-group
               label="Is Active"
               label-for="IsActive"
             >
             
               <b-form-checkbox
               id="isactive"
                               checked="true"
                               class="custom-control-success"
                               v-model="Data.is_active"
                               switch
                 />
 
               <b-form-invalid-feedback>
                 {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
             </b-form-group>
           </validation-provider>
           <div class="d-flex mt-2">
             <b-button
               v-ripple.400="'rgba(255, 255, 255, 0.15)'"
               variant="primary"
               class="mr-2"
               type="submit"
             >
               Submit
             </b-button>
             <b-button
               v-ripple.400="'rgba(186, 191, 199, 0.15)'"
               type="button"
               variant="outline-secondary"
               @click="hide_form"
             >
               Cancel
             </b-button>
           </div>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
 import {
    BForm, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink
 } from 'bootstrap-vue'
import {
  BButton, BFormGroup, BFormInput, BModal, VBModal, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password,confirmed } from '@validations'
 import formValidation from '@core/comp-functions/forms/form-validation'
 import store from '@/store'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import { useToast } from 'vue-toastification/composition'
export default {
    components: {
        BButton,
        BFormGroup,
        BFormInput,
        BListGroup,
        BListGroupItem,
        BModal,
        ValidationProvider,
        ValidationObserver,
        BForm, BFormTextarea, BFormCheckbox, BFormInvalidFeedback, BCard,BCardText,BMedia,BMediaAside,BMediaBody,BAvatar,BImg,BLink
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    model: {
     prop: 'isBannerDetailSidebarActive',
     event: 'update:is-banner-detail-sidebar-active',
   },
    props: {
    isBannerDetailSidebarActive: {
       type: Boolean,
       required: true,
     },
    
     imageData: {
       type: Object,
       required: false,
     },
   },
   setup(props, { emit }){
    const toast = useToast()
    const blankData = {
        id:'',
        image:require('@/assets/images/no-image.png'),
        title:'',
        sub_title:'',
        short_description:'',
        url:''
    };
    const Data = ref(JSON.parse(JSON.stringify(blankData)))
    const getData = (data)=>{
    
        if(data){
            data.is_active = data.is_active?true:false;
            Data.value  = JSON.parse(JSON.stringify(data));
        }
    }
    const resetData = () => {
           Data.value = JSON.parse(JSON.stringify(blankData))
    }
    const onSubmit = async () => {
        //updateImageData
        console.log('Data',Data.value);
        Data.value.is_active = Data.value.is_active?1:0;
        store.dispatch('app-banner/updateImageData', Data.value)
                .then((res) => {
                    
                    if(res.data.success){
                    toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: `Success`,
                        icon: 'BellIcon',
                        variant: 'success',
                        text: `${res.data.message}`,
                        },
                    });
                    emit('refetch-data')
                    emit('update:is-banner-detail-sidebar-active', false)
                    }else{
                    
                    toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: `Error`,
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: `${res.data.message}`,
                        },
                    });
                
                    
                    }
                
                })

    }
    const hide_form = ()=>{
        emit('update:is-banner-detail-sidebar-active', false)
    }
    const {
       refFormObserver,
       
      
       getValidationState,
       resetForm,
     } = formValidation(resetData)
    return {
        onSubmit,
        getData,
        Data,
        refFormObserver,
       getValidationState,
       resetForm,
       resetData,
      
       hide_form
    }

   }
}
</script>